enum carsActionTypes {
  GET_CAR_SAGA = "GET_CAR_SAGA",
  GET_CAR = "GET_CAR",
  ADD_CAR_SAGA = "ADD_CAR_SAGA",
  ADD_CAR = "ADD_CAR",
  EDIT_CAR_SAGA = "EDIT_CAR_SAGA",
  EDIT_CAR = "EDIT_CAR"
}

export default carsActionTypes;

export interface GetCarAction{
  type: carsActionTypes.GET_CAR_SAGA;
  token: string;
  auth: boolean;
  carId: string;
}

export interface AddCarAction {
  type: carsActionTypes.ADD_CAR_SAGA;
  token: string;
  auth: boolean;
  data: {
    plateNumber: string;
    color: string;
    kilometer: string;
    type: string;
    model: string;
    structureNo: string;
    size: string;
    clientEmail: string;
  };
}

export interface EditCarAction {
  type: carsActionTypes.EDIT_CAR_SAGA;
  token: string;
  auth: boolean;
  data: {
    plateNumber?: string;
    color?: string;
    kilometer?: string;
    type?: string;
    model?: string;
    structureNo?: string;
    size?: string;
    clientEmail?: string;
  };
  id: string
}