import React from "react";
import Table from "../../components/Table";
import searchIcon from "../../images/search.svg";

interface Props {}

const openCards = [
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Open",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Waiting",
  },
];

const closedCards = [
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    department: "Maintenance",
    status: "Closed",
  },
];

const MechMaintenanceCards = (props: Props) => {
  return (
    <main className="Cards Mech">
      {/* Open cards */}
      <section>
        <h2 className="Heading">Open maintenance cards</h2>
        <Table
          header={[
            "Card ID",
            "Plate number",
            "Date in",
            "Date out",
            "Department",
            "Status",
          ]}
          rows={openCards}
          link={[
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
          ]}
        />
      </section>

      {/* Closed cards */}
      <section>
        <header className="Mech__Cards__header">
          <h2 className="Heading">Maintenance cards archive</h2>

          <div className="Cards__header__filter">
            <div className="Search">
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search for clients"
                className="Search__input"
              />
              <img
                className="Search__icon"
                src={searchIcon}
                alt="Search icon"
              />
            </div>

            <div className="Sort">
              <span>Sort by</span>
              <select name="sort" id="sort">
                <option value=""></option>
                <option value="id">Card ID</option>
                <option value="number">Plate number</option>
                <option value="date-in">Date in</option>
                <option value="date-out">Date out</option>
                <option value="department">Department</option>
                <option value="status">Status</option>
              </select>
            </div>
          </div>
        </header>
        <Table
          header={[
            "Card ID",
            "Plate number",
            "Date in",
            "Date out",
            "Department",
            "Status",
          ]}
          rows={closedCards}
          link={[
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
            "/mech/card/1",
          ]}
        />
      </section>
    </main>
  );
};

export default MechMaintenanceCards;
