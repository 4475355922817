import React, { useState } from "react";
import CardInformation from "../../components/CardInformation";
import MechEngChat from "../../components/MechEngChat";
import RepairNotes from "../../components/RepairNotes";
import WorkProgress from "../../components/WorkProgress";
import { Link } from "react-router-dom";
import RepairParts from "../../components/RepairParts";

interface Props {}

// 0 => Checkup
// 1 => Get parts => Request parts
// 2 => Get parts => Waiting
// 3 => Get parts => Confirmed
// 4 => Maintenance
// 5 => Car test
// 6 => Finished

const repairNotes = [
  {
    name: "Start maintenance",
    date: "5:30 PM - 22 Mar, 2021",
  },
  {
    name: "Remove old part",
    date: "5:30 PM - 22 Mar, 2021",
  },
  {
    name: "Add the new part Four gray shock absorbers, Car Exhaust system Spark plug",
    date: "5:30 PM - 22 Mar, 2021",
  },
  {
    name: "Finished add new parts",
    date: "5:30 PM - 22 Mar, 2021",
  },
];

const parts = [
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
];

const chat = [
  {
    send: true,
    text: "I'll take 30 minutes a break for lunch",
    time: "5:30 PM - 22 Mar, 2021",
  },
  {
    send: false,
    text: "I'll take 30 minutes a break for lunch",
    time: "5:30 PM - 22 Mar, 2021",
  },
  {
    send: true,
    text: "I'll take 30 minutes a break for lunch I'll take 30 minutes a break for lunch",
    time: "5:30 PM - 22 Mar, 2021",
  },
];

const EngCard = (props: Props) => {
  const [section, setSection] = useState<"info" | "history">("history");
  const [progress, setProgress] = useState<number>(0);

  return (
    <>
      <header className="Card__header">
        <h1>Maintenance card #15236</h1>
      </header>

      <main>
        <div className="Card__btns">
          <button
            className={section === "history" ? "active" : ""}
            onClick={() => setSection("history")}
          >
            Work history
          </button>
          <button
            className={section === "info" ? "active" : ""}
            onClick={() => setSection("info")}
          >
            Card Information
          </button>
        </div>

        <section className="Card__section">
          {section === "info" ? (
            <CardInformation
              loading={false}
              card={{
                id: 0,
                notes: "",
                department: "",
                status: "",
                dateOut: "",
                dateCreated: "",
                pictures: [],
                steps: [],
                car: {
                  id: 0,
                  color: "",
                  kilometer: "",
                  model: "",
                  plateNumber: "",
                  size: "",
                  structureNo: "",
                  type: "",
                },
                client: {
                  address: "",
                  email: "",
                  name: "",
                  nationalId: "",
                  phoneNumber: "",
                  profilePicture: "",
                  state: "",
                  whatsApp: "",
                },
              }}
            />
          ) : (
            <>
              <WorkProgress
                progress={progress}
                loading={false}
                link={true}
                onChange={(value: number) => setProgress(value)}
              />
              <div className="Card__section__content">
                {progress === 0 || progress === 4 || progress === 5 ? (
                  <div className="Card__section__content__notes">
                    <RepairNotes notes={repairNotes} />
                  </div>
                ) : progress === 1 ? (
                  <Link to="/mech/select-parts" className="btn btn-red">
                    Request parts
                  </Link>
                ) : progress === 6 ? (
                  <span className="btn btn-green Card__section__content__finish">
                    Task Finished
                  </span>
                ) : progress === 2 || progress === 3 ? (
                  <div className="Card__section__content__parts">
                    <RepairParts progress={progress} parts={parts} />
                  </div>
                ) : undefined}
                <div className="Card__section__content__chat">
                  <MechEngChat chat={chat} />
                </div>
              </div>
            </>
          )}
        </section>
      </main>
    </>
  );
};

export default EngCard;
