import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import searchIcon from "../../images/search.svg";

interface Props {}

const parts = [
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/75",
  },
  {
    name: "black and brown air filter, Toyota RAV4 Car Oil filter Toyota",
    picture: "https://via.placeholder.com/75",
  },
  {
    name: "Gear mechanical engineering graphy, others, angle, mechanical",
    picture: "https://via.placeholder.com/75",
  },
  {
    name: "Custom wheel Car Rim Alloy wheel, wheel rim, truck, transport, vehicle",
    picture: "https://via.placeholder.com/75",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/75",
  },
];

const SelectParts = (props: Props) => {
  const heightRef = useRef<HTMLDivElement[] | null[]>([]);
  const [heights, setHeights] = useState<(number | undefined)[]>([]);
  const [quantity, setQuantity] = useState<number[]>([]);

  useEffect(() => {
    heightRef.current = heightRef.current.slice(0, parts.length);
    setQuantity(new Array(parts.length).fill(1));
  }, []);

  useEffect(() => {
    setHeights(
      heightRef.current.map((el: HTMLDivElement | null) => el?.offsetHeight)
    );
  }, [heightRef]);

  return (
    <>
      <header className="Card__header">
        <h2 className="Heading">Select parts</h2>
        <Link to="/mech/card/1" className="btn btn-grey">
          Back
        </Link>
      </header>

      <main className="Select-parts">
        {/* Filter */}
        <div className="Select-parts__filter">
          <h3>Filter parts</h3>
          <div className="Select-parts__filter__group">
            {/* Category */}
            <div>
              <label htmlFor="category">Category</label>
              <select name="category" id="category" required defaultValue="">
                <option value="" disabled>
                  Select part category
                </option>
                <option value="id">Card ID</option>
                <option value="number">Plate number</option>
                <option value="date-in">Date in</option>
                <option value="date-out">Date out</option>
                <option value="department">Department</option>
                <option value="status">Status</option>
              </select>
            </div>
            {/* Type */}
            <div>
              <label htmlFor="type">Type</label>
              <select name="type" id="type" required defaultValue="">
                <option value="" disabled>
                  Select car maker
                </option>
                <option value="id">Card ID</option>
                <option value="number">Plate number</option>
                <option value="date-in">Date in</option>
                <option value="date-out">Date out</option>
                <option value="department">Department</option>
                <option value="status">Status</option>
              </select>
            </div>
            {/* Model */}
            <div>
              <label htmlFor="model">Model</label>
              <select name="model" id="model" required defaultValue="">
                <option value="" disabled>
                  Select car model
                </option>
                <option value="id">Card ID</option>
                <option value="number">Plate number</option>
                <option value="date-in">Date in</option>
                <option value="date-out">Date out</option>
                <option value="department">Department</option>
                <option value="status">Status</option>
              </select>
            </div>
            {/* Search */}
            <div>
              <label htmlFor="search">Search</label>
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Enter part name here..."
                className="Search__input"
              />
              <img
                className="Search__icon"
                src={searchIcon}
                alt="Search icon"
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="Table">
          <table className="Table__container">
            <thead>
              <tr className="Table__container__header">
                <th>Product</th>
                <th>Quantity</th>
              </tr>
            </thead>

            <tbody>
              {parts.map((part, i) => (
                <tr key={i} className="Table__container__row Select-parts__row">
                  <td>
                    <div ref={(el) => (heightRef.current[i] = el)}>
                      <input
                        type="checkbox"
                        name="part"
                        id={`part-${i}`}
                        hidden
                      />
                      <label htmlFor={`part-${i}`}></label>
                      <img src={part.picture} alt="Part" />
                      <span>{part.name}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        height: `${heights[i]}px`,
                      }}
                    >
                      <button
                        className="btn btn-blue"
                        onClick={() => {
                          if (quantity[i] === 1) return;
                          setQuantity([
                            ...quantity.slice(0, i),
                            quantity[i] - 1,
                            ...quantity.slice(i + 1),
                          ]);
                        }}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        name={`quantity-${i}`}
                        id={`quantity-${i}`}
                        min={1}
                        value={quantity[i] || 1}
                        onChange={(e) =>
                          setQuantity([
                            ...quantity.slice(0, i),
                            +e.currentTarget.value,
                            ...quantity.slice(i + 1),
                          ])
                        }
                      />
                      <button
                        className="btn btn-blue"
                        onClick={() =>
                          setQuantity([
                            ...quantity.slice(0, i),
                            quantity[i] + 1,
                            ...quantity.slice(i + 1),
                          ])
                        }
                      >
                        +
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <footer>
          <p>Showing 10 of 596 parts</p>
          <div className="Select-parts__page">
            <button className="btn btn-grey">Previous</button>
            <button className="btn btn-blue">Next</button>
          </div>
          <button className="btn btn-red">Request parts</button>
        </footer>
      </main>
    </>
  );
};

export default SelectParts;
