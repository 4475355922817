import React from "react";
import timeIcon from "../images/clock-grey.svg";
import SendMsg from "../images/send-light.svg";

interface Props {
  chat: {
    send: boolean;
    text: string;
    time: string;
  }[];
}

const MechEngChat: React.FC<Props> = ({ chat }) => {
  return (
    <>
      <h2 className="Heading">Engineer contact</h2>
      <div className="Card__section__content__chat__box">
        {chat.map((msg, i) => (
          <div
            className={`Card__section__content__chat__box__${
              msg.send ? "sender" : "reciver"
            }`}
            key={i}
          >
            <img src="https://via.placeholder.com/32" alt="Sender avatar" />
            <span
              className={`Card__section__content__chat__box__${
                msg.send ? "sender" : "reciver"
              }__time`}
            >
              <img src={timeIcon} alt="Time icon" />
              {msg.time}
            </span>
            <p
              className={`Card__section__content__chat__box__${
                msg.send ? "sender" : "reciver"
              }__msg`}
            >
              {msg.text}
            </p>
          </div>
        ))}
        <div className="Card__section__content__notes__form">
          <div className="chat">
            <input
              type="text"
              id="chat"
              name="chat"
              placeholder="Type here your message..."
            />{" "}
            <button className="btn-dark">
              <img src={SendMsg} alt="Send message" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MechEngChat;
