import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { ICar, IStore } from "../../storeTypes";
import { actionTypes } from "../../actionTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";
import Loading from "../../components/Loading";

interface ICarData {
  plateNumber: string;
  kilometer: string;
  type: string;
  model: string;
  size: string;
  color: string;
  structureNo: string;
  clientEmail: string;
}

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  success: any;
  car: ICar;
  addCar: (token: string, auth: boolean, data: ICarData) => void;
  editCar: (token: string, auth: boolean, data: ICarData, id: string) => void;
  getCar: (token: string, auth: boolean, id: string) => void;
  history: any;
  match: any;
}

const AddCar: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  success,
  car,
  addCar,
  editCar,
  getCar,
  history,
  match,
}) => {
  // Get car data in edit
  useEffect(() => {
    if (match.params.id)
      refreshTokenFunc(
        token,
        expiration,
        refreshToken,
        refreshTokenExpiration,
        history,
        () => {},
        ["Receptionist"],
        (token: string, auth: boolean) => getCar(token, auth, match.params.id)
      );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    match,
    getCar,
  ]);

  useEffect(() => {
    if (success.addCar) history.push(`/rec/client/${match.params.clientEmail}`);
  }, [success, history, match]);

  const addCarSchema = Yup.object().shape({
    plateNumber: Yup.string().required("You must type plate number"),
    kilometer: Yup.number().required("You must type number of kilometers"),
    type: Yup.string().required("You must choose type"),
    model: Yup.string().required("You must choose model"),
    size: Yup.string().required("You must choose size"),
    color: Yup.string().required("You must type color"),
    structureNo: Yup.number().required("You must type structure number"),
  });

  const submitForm = (values: any) => {
    const data: ICarData = {
      ...values,
      clientEmail: match.params.clientEmail,
    };

    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) =>
        match.params.id
          ? editCar(token, auth, data, match.params.id)
          : addCar(token, auth, data)
    );
  };

  return (
    <>
      <header>
        <h1 className="Form__heading">Add new car</h1>
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{
              plateNumber: match.params.id ? car.plateNumber : "",
              kilometer: match.params.id ? car.kilometer : "",
              type: match.params.id ? car.type : "",
              model: match.params.id ? car.model : "",
              size: match.params.id ? car.size : "",
              color: match.params.id ? car.color : "",
              structureNo: match.params.id ? car.structureNo : "",
            }}
            onSubmit={submitForm}
            validationSchema={addCarSchema}
          >
            {({ errors, touched }) => (
              <Form className="Form__container">
                <h2 className="Form__container__heading">Car info</h2>
                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="plateNumber">Plate number</label>
                    <Field id="plateNumber" name="plateNumber" />
                  </div>
                  <div className="Form__container__row__input">
                    <label htmlFor="kilometer">Kilo meter</label>
                    <Field id="kilometer" name="kilometer" />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="type">Type</label>
                    <Field id="type" name="type" as="select">
                      <option value="" disabled></option>
                      <option value="Riydah">Riydah</option>
                      <option value="Makkah">Makkah</option>
                      <option value="Dmam">Dmam</option>
                    </Field>
                  </div>
                  <div className="Form__container__row__input">
                    <label htmlFor="model">Model</label>
                    <Field id="model" name="model" as="select">
                      <option value="" disabled></option>
                      <option value="Riydah">Riydah</option>
                      <option value="Makkah">Makkah</option>
                      <option value="Dmam">Dmam</option>
                    </Field>
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="size">Size</label>
                    <Field id="size" name="size" as="select">
                      <option value="" disabled></option>
                      <option value="Riydah">Riydah</option>
                      <option value="Makkah">Makkah</option>
                      <option value="Dmam">Dmam</option>
                    </Field>
                  </div>
                  <div className="Form__container__row__input">
                    <label htmlFor="color">Color</label>
                    <Field id="color" name="color" />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="structureNo">Structure No.</label>
                    <Field
                      id="structureNo"
                      name="structureNo"
                      placeholder="ex: 123456789"
                    />
                  </div>
                </div>

                <div className="Form__container__errors">
                  {errors.plateNumber && touched.plateNumber ? (
                    <p className="error">{errors.plateNumber}</p>
                  ) : null}
                  {errors.kilometer && touched.kilometer ? (
                    <p className="error">{errors.kilometer}</p>
                  ) : null}
                  {errors.type && touched.type ? (
                    <p className="error">{errors.type}</p>
                  ) : null}
                  {errors.model && touched.model ? (
                    <p className="error">{errors.model}</p>
                  ) : null}
                  {errors.size && touched.size ? (
                    <p className="error">{errors.size}</p>
                  ) : null}
                  {errors.color && touched.color ? (
                    <p className="error">{errors.color}</p>
                  ) : null}
                  {errors.structureNo && touched.structureNo ? (
                    <p className="error">{errors.structureNo}</p>
                  ) : null}
                </div>

                <button className="btn btn-red" type="submit">
                  Add new car
                </button>
              </Form>
            )}
          </Formik>
        )}
      </header>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  success: state.car.success,
  car: state.car.car,
});

const mapDispatchToProps = (dispatch: any) => ({
  addCar: (token: string, auth: boolean, data: ICarData) =>
    dispatch({ type: actionTypes.ADD_CAR_SAGA, token, auth, data }),
  editCar: (token: string, auth: boolean, data: ICarData, id: string) =>
    dispatch({ type: actionTypes.EDIT_CAR_SAGA, token, auth, data, id }),
  getCar: (token: string, auth: boolean, carId: string) =>
    dispatch({ type: actionTypes.GET_CAR_SAGA, token, auth, carId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCar);
