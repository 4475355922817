import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";

const Loading = () => {
  const override = css`
    display: block;
    margin: 40px auto;
  `;

  return <DotLoader color="#dddddd" size="100px" css={override} />;
};

export default Loading;
