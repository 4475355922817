import React from "react";
import Table from "../../components/Table";
import searchIcon from "../../images/search.svg";

interface Props {}

const cards = [
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Open",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Waiting",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Open",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Open",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Waiting",
  },
];

const EngMaintenanceCards: React.FC<Props> = () => {
  return (
    <>
      <header className="Cards__header">
        <h2 className="Heading">Maintenance cards</h2>

        <div className="Cards__header__filter">
          <div className="Search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search for clients"
              className="Search__input"
            />
            <img className="Search__icon" src={searchIcon} alt="Search icon" />
          </div>

          <div className="Sort">
            <span>Sort by</span>
            <select name="sort" id="sort">
              <option value=""></option>
              <option value="id">Card ID</option>
              <option value="number">Plate number</option>
              <option value="date-in">Date in</option>
              <option value="date-out">Date out</option>
              <option value="status">Status</option>
            </select>
          </div>
        </div>
      </header>

      <main className="Cards__table">
        <Table
          header={[
            "Card ID",
            "Plate number",
            "Date in",
            "Date out",
            "Status",
          ]}
          rows={cards}
          link={[
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
          ]}
        />
      </main>
    </>
  );
};

export default EngMaintenanceCards;
