import jwt_decode from "jwt-decode";
import { IStore } from "../storeTypes";
import cookie from "react-cookies";

const loadState = () =>
  cookie.load("somo")
    ? cookie.load("somo")
    : {
        token: "",
        expiration: "",
        refreshToken: "",
        refreshTokenExpiration: "",
        userImage: "",
      };

const { Name, Email, UserType } = loadState().token
  ? (jwt_decode(loadState().token) as any)
  : { Name: "", Email: "", UserType: "" };

export const defaultStore: IStore = {
  user: {
    token: loadState().token,
    expiration: loadState().expiration,
    refreshToken: loadState().refreshToken,
    refreshTokenExpiration: loadState().refreshTokenExpiration,
    name: loadState().token ? Name : "",
    email: loadState().token ? Email : "",
    userType: loadState().token ? UserType : "",
    errors: [],
  },
  clients: {
    clients: [],
    client: {
      email: "",
      phoneNumber: "",
      profilePicture: "",
      name: "",
      whatsApp: "",
      address: "",
      state: "",
      nationalId: "",
      isCompleted: false,
      userType: "",
      cars: [],
    },
    success: {},
    erorrs: [],
  },
  cards: {
    cards: [],
    card: {
      id: 0,
      notes: "",
      department: "",
      status: "",
      dateOut: "",
      dateCreated: "",
      pictures: [],
      steps: [],
      car: {
        id: 0,
        color: "",
        kilometer: "",
        model: "",
        plateNumber: "",
        size: "",
        structureNo: "",
        type: "",
      },
      client: {
        address: "",
        email: "",
        name: "",
        nationalId: "",
        phoneNumber: "",
        profilePicture: "",
        state: "",
        whatsApp: "",
      },
    },
    success: {},
    errors: []
  },
  car: {
    car: {
      id: 0,
      color: "",
      kilometer: "",
      model: "",
      plateNumber: "",
      size: "",
      structureNo: "",
      type: "",
      maintenanceCards: [],
    },
    success: {},
    errors: [],
  },
  loading: false,
};
