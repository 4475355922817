import React, { useEffect } from "react";
import { connect } from "react-redux";
import welcomeImage from "../../images/welcome.svg";
import clientsIcon from "../../images/clients.svg";
import operCardsIcon from "../../images/open-cards.svg";
import waitingCardsIcon from "../../images/waiting-cards.svg";
import allCardsIcon from "../../images/document-active.svg";
import Table from "../../components/Table";
import { IClient, IStore } from "../../storeTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";
import { actionTypes } from "../../actionTypes";
import Loading from "../../components/Loading";

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  name: string;
  clients: IClient[];
  cards: {
    id: number;
    dateOut: string;
    department: string;
    status: string;
    dateCreated: string;
    plateNumber: string;
  }[];
  getClients: (token: string, auth: boolean) => void;
  getCards: (token: string, auth: boolean) => void;
  history: any;
}

const Home: React.FC<Props> = ({
  token,
  refreshToken,
  refreshTokenExpiration,
  expiration,
  loading,
  name,
  clients,
  cards,
  getClients,
  getCards,
  history,
}) => {
  useEffect(() => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) => {
        getClients(token, auth);
        getCards(token, auth);
      }
    );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    getClients,
    getCards,
  ]);

  return (
    <>
      <header className="Home__header">
        <div className="Home__header__welcome">
          <h1>
            Welcome back, <span>{name}</span>
          </h1>
          <p>Work hard, your job is your life</p>
        </div>
        <img src={welcomeImage} alt="Welcome" />
      </header>

      <main>
        {/* Overview */}
        <section className="Home__overview">
          <h2 className="Heading">Overview</h2>

          <div className="Home__overview__cards">
            {/* Clients Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--clients">
                <img src={clientsIcon} alt="Clients icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>Our clients</h3>
                <p>{clients.length}</p>
              </div>
            </div>
            {/* Open cards Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--open">
                <img src={operCardsIcon} alt="Open cards icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>Open cards</h3>
                <p>{cards.filter((card) => card.status === "open").length}</p>
              </div>
            </div>
            {/* Waiting cards Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--waiting">
                <img src={waitingCardsIcon} alt="Waiting cards icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>Waiting cards</h3>
                <p>
                  {cards.filter((card) => card.status === "waiting").length}
                </p>
              </div>
            </div>
            {/* All cards Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--all">
                <img src={allCardsIcon} alt="All cards icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>All cards</h3>
                <p>{cards.length}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Tables */}
        <div className="Home__tables">
          {/* Cards */}
          <section className="Home__tables__table Home__tables__table--cards">
            <h2 className="Heading">Recent cards</h2>
            {loading ? (
              <Loading />
            ) : (
              <Table
                header={["Card ID", "Plate number", "Status"]}
                rows={cards.map((card) => ({
                  id: card.id,
                  plateNumber: card.plateNumber,
                  status: card.status,
                }))}
              />
            )}
          </section>

          {/* Clients */}
          <section className="Home__tables__table Home__tables__table--clients">
            <h2 className="Heading">New Clients</h2>
            {loading ? (
              <Loading />
            ) : (
              <Table
                header={["Client name", "Mobile number"]}
                rows={clients.slice(0, 5).map((client) => ({
                  name: client.name,
                  phoneNumber: client.phoneNumber,
                }))}
              />
            )}
          </section>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  name: state.user.name,
  clients: state.clients.clients,
  cards: state.cards.cards,
});

const mapDispatchToProps = (dispatch: any) => ({
  getClients: (token: string, auth: boolean) =>
    dispatch({ type: actionTypes.GET_ALL_CLIENTS_SAGA, token, auth }),
  getCards: (token: string, auth: boolean) =>
    dispatch({ type: actionTypes.GET_ALL_CARDS_SAGA, token, auth }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
