import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { IClient, IStore } from "../../storeTypes";
import { actionTypes } from "../../actionTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";
import Loading from "../../components/Loading";

interface IClientData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  whatsApp: string;
  address: string;
  state: string;
  nationalId: string;
}

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  success: any;
  client: IClient;
  addClient: (token: string, auth: boolean, data: IClientData) => void;
  editClient: (
    token: string,
    auth: boolean,
    data: IClientData,
    email: string
  ) => void;
  getClient: (token: string, auth: boolean, email: string) => void;
  history: any;
  match: any;
}

const AddClient: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  success,
  client,
  addClient,
  editClient,
  getClient,
  history,
  match,
}) => {
  // Get client data in edit
  useEffect(() => {
    if (match.params.email)
      refreshTokenFunc(
        token,
        expiration,
        refreshToken,
        refreshTokenExpiration,
        history,
        () => {},
        ["Receptionist"],
        (token: string, auth: boolean) =>
          getClient(token, auth, match.params.email)
      );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    match,
    getClient,
  ]);

  useEffect(() => {
    if (success.addClient) history.push("/rec/clients");
  }, [success, history]);

  const addClientSchema = Yup.object().shape({
    firstName: Yup.string().required("You must type first name"),
    lastName: Yup.string().required("You must type last name"),
    nationalId: Yup.string().required("You must type national ID"),
    state: Yup.string().required("You must choose state"),
    address: Yup.string().required("You must type address"),
    phoneNumber: Yup.string().required("You must type phone number"),
    whatsApp: Yup.string().required("You must type whatsapp number"),
    email: Yup.string()
      .required("You must type email")
      .email("You must type a vaild email"),
  });

  const submitForm = (data: IClientData) => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) =>
        match.params.email
          ? editClient(token, auth, data, match.params.email)
          : addClient(token, auth, data)
    );
  };

  return (
    <>
      <header>
        <h1 className="Form__heading">Add new client</h1>
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{
              firstName: match.params.email ? client.name.split(" ")[0] : "",
              lastName: match.params.email ? client.name.split(" ")[1] : "",
              nationalId: match.params.email ? client.nationalId : "",
              state: match.params.email ? client.state : "",
              address: match.params.email ? client.address : "",
              phoneNumber: match.params.email ? client.phoneNumber : "",
              whatsApp: match.params.email ? client.whatsApp : "",
              email: match.params.email ? client.email : "",
            }}
            onSubmit={submitForm}
            validationSchema={addClientSchema}
          >
            {({ errors, touched }) => (
              <Form className="Form__container">
                <h2 className="Form__container__heading">Cient info</h2>
                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="firstName">Client name</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                    />{" "}
                  </div>
                  <div className="Form__container__row__input">
                    <Field
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="nationalId">National ID</label>
                    <Field id="nationalId" name="nationalId" />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="state">State</label>
                    <Field id="state" name="state" as="select">
                      <option value="" disabled>
                        Select your state ex: Riydah
                      </option>
                      <option value="Riydah">Riydah</option>
                      <option value="Makkah">Makkah</option>
                      <option value="Dmam">Dmam</option>
                    </Field>
                  </div>
                  <div className="Form__container__row__input">
                    <label htmlFor="address">Address</label>
                    <Field
                      id="address"
                      name="address"
                      placeholder="Ex : RJ89+R7, King Fahd Branch Rd, Al Malqa"
                    />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="phoneNumber">Mobile</label>
                    <Field id="phoneNumber" name="phoneNumber" />
                  </div>
                  <div className="Form__container__row__input">
                    <label htmlFor="whatsApp">Whatsapp</label>
                    <Field id="whatsApp" name="whatsApp" />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input Form__container__row__input--full-width">
                    <label htmlFor="email">Email</label>
                    <Field id="email" name="email" />
                  </div>
                </div>

                <div className="Form__container__errors">
                  {errors.firstName && touched.firstName ? (
                    <p className="error">{errors.firstName}</p>
                  ) : null}
                  {errors.lastName && touched.lastName ? (
                    <p className="error">{errors.lastName}</p>
                  ) : null}
                  {errors.nationalId && touched.nationalId ? (
                    <p className="error">{errors.nationalId}</p>
                  ) : null}
                  {errors.state && touched.state ? (
                    <p className="error">{errors.state}</p>
                  ) : null}
                  {errors.address && touched.address ? (
                    <p className="error">{errors.address}</p>
                  ) : null}
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <p className="error">{errors.phoneNumber}</p>
                  ) : null}
                  {errors.whatsApp && touched.whatsApp ? (
                    <p className="error">{errors.whatsApp}</p>
                  ) : null}
                  {errors.email && touched.email ? (
                    <p className="error">{errors.email}</p>
                  ) : null}
                </div>

                <button className="btn btn-red" type="submit">
                  Create new client
                </button>
              </Form>
            )}
          </Formik>
        )}
      </header>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  success: state.clients.success,
  client: state.clients.client,
});

const mapDispatchToProps = (dispatch: any) => ({
  addClient: (token: string, auth: boolean, data: IClientData) =>
    dispatch({ type: actionTypes.ADD_CLIENT_SAGA, token, auth, data }),
  editClient: (
    token: string,
    auth: boolean,
    data: IClientData,
    email: string
  ) =>
    dispatch({ type: actionTypes.EDIT_CLIENT_SAGA, token, auth, data, email }),
  getClient: (token: string, auth: boolean, email: string) =>
    dispatch({ type: actionTypes.GET_CLIENT_SAGA, token, auth, email }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClient);
