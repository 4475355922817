import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionTypes } from "../../actionTypes";
import CardInformation from "../../components/CardInformation";
import WorkProgress from "../../components/WorkProgress";
import { ICard, IStore } from "../../storeTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  card: ICard;
  getCard: (token: string, auth: boolean, cardId: string) => void;
  history: any;
  match: any;
}

const CardInfo: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  card,
  getCard,
  history,
  match,
}) => {
  const [section, setSection] = useState<"info" | "history">("info");

  useEffect(() => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) =>
        section === "info" ? getCard(token, auth, match.params.id) : undefined
    );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    match,
    getCard,
    section,
  ]);

  return (
    <>
      <header className="Card__header">
        <h1>Maintenance card #{card.id}</h1>
        <button>QR Code</button>
      </header>

      <main>
        <div className="Card__btns">
          <button
            className={section === "info" ? "active" : ""}
            onClick={() => setSection("info")}
          >
            Card Information
          </button>
          <button
            className={section === "history" ? "active" : ""}
            onClick={() => setSection("history")}
          >
            Work history
          </button>
        </div>

        <section className="Card__section">
          {section === "info" ? (
            <CardInformation card={card} loading={loading} />
          ) : (
            <WorkProgress
              progress={card.steps.filter((step) => step.isFinished).length}
              loading={loading}
            />
          )}
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  card: state.cards.card,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCard: (token: string, auth: boolean, cardId: string) =>
    dispatch({ type: actionTypes.GET_CARD_SAGA, token, auth, cardId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardInfo);
