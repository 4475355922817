import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import userDefault from "../../images/default-user.svg";
import userIcon from "../../images/profile.svg";
import locationIcon from "../../images/location.svg";
import nationalIDIcon from "../../images/id.svg";
import emailIcon from "../../images/email.svg";
import mobileIcon from "../../images/phone.svg";
import whatsappIcon from "../../images/whatsapp.svg";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import InfoCard from "../../components/InfoCard";
import { IStore } from "../../storeTypes";
import { actionTypes } from "../../actionTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";
import Loading from "../../components/Loading";

interface IClient {
  email: string;
  phoneNumber: string;
  profilePicture: string;
  name: string;
  whatsApp: string;
  address: string;
  state: string;
  nationalId: string;
  isCompleted: boolean;
  userType: string;
  cars: {
    id: string;
    plateNumber: string;
    color: string;
    type: string;
    model: string;
    kilometer: string;
    structureNo: string;
    size: string;
  }[];
}

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  client: IClient;
  getClient: (token: string, auth: boolean, email: string) => void;
  history: any;
  match: any;
}

const ClientInfo: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  client,
  getClient,
  history,
  match,
}) => {
  const [clientInfo, setClientInfo] = useState<
    { key: string; value: string; img: string }[][]
  >([]);

  useEffect(() => {
    setClientInfo([
      [
        {
          key: "Name",
          value: client.name,
          img: userIcon,
        },
      ],
      [
        {
          key: "Address",
          value: client.address,
          img: locationIcon,
        },
        {
          key: "National ID",
          value: client.nationalId,
          img: nationalIDIcon,
        },
      ],
      [
        {
          key: "Email",
          value: client.email,
          img: emailIcon,
        },
        {
          key: "Mobile Num",
          value: client.phoneNumber,
          img: mobileIcon,
        },
        {
          key: "Whatsapp",
          value: client.whatsApp,
          img: whatsappIcon,
        },
      ],
    ]);
  }, [client]);

  useEffect(() => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) =>
        getClient(token, auth, match.params.email)
    );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    match,
    getClient,
  ]);

  return (
    <main>
      {/* Client information */}
      <section className="Client__info">
        <h2 className="Heading">Client information</h2>

        {loading ? (
          <Loading />
        ) : (
          <div className="Client__info__card">
            <img
              src={client.profilePicture || userDefault}
              alt="User avatar"
              className="Client__info__card__avatar"
            />

            <InfoCard info={clientInfo} />

            <Link
              to={`/rec/add-client/${client.email}`}
              className="btn btn-green"
            >
              Edit information
            </Link>
          </div>
        )}
      </section>

      {/* Client cars */}
      <section className="Client__cars">
        <header>
          <h2 className="Heading">Client cars</h2>
          <Link to={`/rec/add-car/${client.email}`} className="btn btn-green">
            Add new car
          </Link>
        </header>
        {loading ? (
          <Loading />
        ) : (
          <Table
            header={["Plate number", "Type", "Model", "Color"]}
            rows={client.cars.map(({ plateNumber, type, model, color }) => ({
              plateNumber,
              type,
              model,
              color,
            }))}
            link={client.cars.map(
              (car) => `/rec/car/${client.email}/${car.id}`
            )}
          />
        )}
      </section>
    </main>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  client: state.clients.client,
});

const mapDispatchToProps = (dispatch: any) => ({
  getClient: (token: string, auth: boolean, email: string) =>
    dispatch({ type: actionTypes.GET_CLIENT_SAGA, token, auth, email }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo);
