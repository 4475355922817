import { takeEvery } from "redux-saga/effects";
import { actionTypes, cardsActions } from "../actionTypes";
import { helperSaga } from "./mainSaga";

function* GetAllCards({ token, auth }: cardsActions.GetCardsAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.GET_ALL_CARDS,
    type: "get",
    url: "MaintenanceCards",
    getResult: (data: any) => {
      let res = [...data];
      res.forEach((obj: any) => {
        const localDateCreated = new Date(
          new Date(obj.dateCreated).getTime() + 2 * 60 * 60 * 1000
        )
          .toDateString()
          .split(" ");
        obj.dateCreated = `${localDateCreated[2]} ${localDateCreated[1]}, ${localDateCreated[3]}`;
        const localDateOut = new Date(
          new Date(obj.dateOut).getTime() + 2 * 60 * 60 * 1000
        )
          .toDateString()
          .split(" ");
        obj.dateOut = `${localDateOut[2]} ${localDateOut[1]}, ${localDateOut[3]}`;
      });
      return res;
    },
  });
}

function* AddCard({ token, auth, data }: cardsActions.AddCardAction) {
  let form = new FormData();
  form.append("Notes", data.notes);
  if (data.department) form.append("Department", data.department.toString());
  if (data.pictures) {
    data.pictures.map((pic, i) => form.append(`Pictures`, pic));
  }
  form.append("CarPlateNumber", data.plateNumber);

  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.ADD_CARD,
    type: "post",
    url: "MaintenanceCards",
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function* GetCard({ token, auth, cardId }: cardsActions.GetCardAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.GET_CARD,
    type: "get",
    url: `MaintenanceCards/${cardId}`,
    getResult: (data: any) => {
      const { dateModified, ...res } = data;
      const localDateCreated = new Date(
        new Date(res.dateCreated).getTime() + 2 * 60 * 60 * 1000
      )
        .toDateString()
        .split(" ");
      res.dateCreated = `${localDateCreated[2]} ${localDateCreated[1]}, ${localDateCreated[3]}`;
      const localDateOut = new Date(
        new Date(res.dateOut).getTime() + 2 * 60 * 60 * 1000
      )
        .toDateString()
        .split(" ");
      res.dateOut = `${localDateOut[2]} ${localDateOut[1]}, ${localDateOut[3]}`;
      return res;
    },
  });
}

export function* watchCardssSaga() {
  yield takeEvery(actionTypes.GET_ALL_CARDS_SAGA, GetAllCards);
  yield takeEvery(actionTypes.ADD_CARD_SAGA, AddCard);
  yield takeEvery(actionTypes.GET_CARD_SAGA, GetCard);
}
