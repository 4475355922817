import checkToken from "./checkToken";
import jwt_decode from "jwt-decode";

const refreshTokenFunc = (
  token: string,
  expiration: string,
  refreshToken: string,
  refreshTokenExpiration: string,
  history: any,
  postRefreshToken: any,
  auths: string[],
  tokenFunc?: any
) => {
  let {
    UserType,
  }: // isCompleted,
  // EmailConfirmed,
  {
    UserType: string;
    isCompleted: boolean;
    EmailConfirmed: boolean;
  } = token ? (jwt_decode(token) as any) : "";

  // if (EmailConfirmed as boolean) return history.push("/confirm-email");

  if (history && !token)
    history.push({
      state: {
        errorStatusCode: 401,
      },
    });

  if (history && !auths.find((auth) => auth === UserType)) {
    history.push({
      state: {
        errorStatusCode: 401,
        message: "ليس لديك صلاحيات لزيارة هذه الصفحة",
      },
    });
  }

  const tokenAvailable = checkToken(
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration
  );

  if (tokenAvailable.token) {
    if (tokenFunc) tokenFunc(token, true);
  } else if (tokenAvailable.refreshToken) {
    if (tokenFunc) tokenFunc(refreshToken, false);
    else postRefreshToken(refreshToken);
  } else if (history) {
    history.push({
      state: {
        errorStatusCode: 401,
      },
    });
  }
};

export default refreshTokenFunc;
