import React from "react";

interface Props {
  progress: number;
  parts: {
    name: string;
    picture: string;
  }[];
}

const RepairParts: React.FC<Props> = ({ progress, parts }) => {
  return (
    <>
      {progress === 2 ? (
        <span className="btn btn-orange">Waiting Confirm</span>
      ) : (
        <span className="btn btn-green">Confirmed</span>
      )}
      <h2 className="Heading">Parts I need</h2>
      <div className="Card__section__content__parts__box">
        {parts.map((part, i) => (
          <div className="Card__section__content__parts__box__part" key={i}>
            <img src={part.picture} alt="Repair note icon" />
            <p className="Card__section__content__parts__box__part__text">
              {part.name}
            </p>
            {progress === 2 && <button> Remove</button>}
          </div>
        ))}
      </div>
    </>
  );
};

export default RepairParts;
