import React, { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import RegisterSideImg from "../images/login.png";
import Logo from "../images/logo-white.svg";
import { IStore } from "../storeTypes";
import { actionTypes } from "../actionTypes";
import Loading from "../components/Loading";

interface Props {
  userType: string;
  loading: boolean;
  loginUser: (data: { email: string; password: string }) => void;
  history: any;
}

const Login: React.FC<Props> = ({ userType, loading, loginUser, history }) => {
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required("You must type your email")
      .email("You must type a vaild email address"),
    password: Yup.string()
      .required("You must type your password")
      .min(8, "Password must be more than 7 characters"),
  });

  useEffect(() => {
    if (userType) {
      if (userType === "Receptionist") history.push("/rec");
      else if(userType === "Mechanic") history.push("/mech");
      else if(userType === "Engineer") history.push("/eng");
      else if (userType === "PartsManager") history.push("/parts");
    }
  }, [userType, history]);

  return (
    <div className="Register">
      <img src={RegisterSideImg} alt="Register" className="Register__img" />
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={(data) => loginUser(data)}
          validationSchema={loginSchema}
        >
          {({ errors, touched }) => (
            <Form className="Register__form">
              <img src={Logo} alt="Logo" className="Register__form__logo" />
              <h1 className="Heading">Login</h1>
              <div className="Register__form__group">
                <label htmlFor="email">Email</label>
                <Field id="email" type="email" name="email" />
                {errors.email && touched.email && (
                  <p className="Register__form__group__error">{errors.email}</p>
                )}
              </div>
              <div className="Register__form__group">
                <label htmlFor="password">Password</label>
                <Field id="password" type="password" name="password" />
                {errors.password && touched.password && (
                  <p className="Register__form__group__error">
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="Register__form__submit">
                <button className="btn btn-blue" type="submit">
                  Login
                </button>
                <Link to="/forget-password">Forgot your password?</Link>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  userType: state.user.userType,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loginUser: (data: { email: string; password: string }) =>
    dispatch({ type: actionTypes.LOGIN_USER_SAGA, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
