import { takeEvery } from "redux-saga/effects";
import { actionTypes, registerActions } from "../actionTypes";
import { helperSagaUnAuth } from "./mainSaga";

function* LoginUser({ data }: registerActions.LoginAction) {
  yield helperSagaUnAuth({
    actionType: actionTypes.LOGIN_USER,
    type: "post",
    url: "Accounts/Login",
    data,
  });
}

export function* RefreshToken({ token }: any) {
  const data: { token: string } = yield helperSagaUnAuth({
    actionType: actionTypes.REFRESH_TOKEN,
    type: "post",
    url: `Accounts/RefreshToken/${token}`,
    data: undefined,
  });
  return data.token;
}


export function* watchRegisterUser() {
  yield takeEvery(actionTypes.LOGIN_USER_SAGA, LoginUser);
  yield takeEvery(actionTypes.REFRESH_TOKEN_SAGA, RefreshToken);
}
