import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../images/logo.svg";
import HomeIcon from "../../images/home.svg";
import HomeActiveIcon from "../../images/home-active.svg";
import documentIcon from "../../images/document.svg";
import documentActiveIcon from "../../images/document-active.svg";
import userIcon from "../../images/user.svg";
import userActiveIcon from "../../images/user-active.svg";
import logoutIcon from "../../images/logout.svg";

interface Props {
}

const RecSideNav: React.FC<Props> = () => {
  const [toggleSideNav, setToggleSideNav] = useState<boolean>(true);
  const page = (useParams() as { page: string }).page;
  const active = !page
    ? "Home"
    : page === "cards" || page === "card/:id"
    ? "Cards"
    : "User";

  return (
    <nav className={`${toggleSideNav ? "hidden" : ""} Sidenav`}>
      {/* Hamburger menu */}
      <button
        className="Sidenav__hamburger-menu"
        onClick={() => setToggleSideNav(!toggleSideNav)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <Link to="/" className="Sidenav__logo">
        <img src={Logo} alt="Logo" />
      </Link>

      <ul className="Sidenav__list">
        <li
          className={`${active === "Home" ? "active" : ""} Sidenav__list__item`}
        >
          <Link to="/rec" className="Sidenav__list__item__link">
            <img
              src={active === "Home" ? HomeActiveIcon : HomeIcon}
              alt="Home Icon"
            />{" "}
            Home
          </Link>
        </li>
        <li
          className={`${active === "User" ? "active" : ""} Sidenav__list__item`}
        >
          <Link to="/rec/clients" className="Sidenav__list__item__link">
            <img
              src={active === "User" ? userActiveIcon : userIcon}
              alt="User Icon"
            />{" "}
            Clients
          </Link>
        </li>
        <li
          className={`${
            active === "Cards" ? "active" : ""
          } Sidenav__list__item`}
        >
          <Link to="/rec/cards" className="Sidenav__list__item__link">
            <img
              src={active === "Cards" ? documentActiveIcon : documentIcon}
              alt="Cards Icon"
            />{" "}
            Maintenance cards
          </Link>
        </li>
        <li className="Sidenav__list__item">
          <button className="Sidenav__list__item__link">
            <img src={logoutIcon} alt="Logout icon" /> Log out
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default RecSideNav;
