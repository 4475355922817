import React from "react";
import searchIcon from "../../images/search.svg";

interface Props {}

const EngTopNav: React.FC<Props> = () => {
  return (
    <nav className="Topnav">
      {/* Search */}
      <div className="Search">
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Enter search words here..."
          className="Search__input"
        />
        <img className="Search__icon" src={searchIcon} alt="Search icon" />
      </div>

      {/* <Link to="/rec/add-card" className="btn btn-red">
        Create new card
      </Link> */}

      {/* User */}
      <div className="Topnav__user">
        <p>Ahmed Mostafa</p>
        <img src="https://via.placeholder.com/32" alt="User avatar" />
      </div>
    </nav>
  );
};

export default EngTopNav;
