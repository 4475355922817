import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionTypes } from "../../actionTypes";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import searchIcon from "../../images/search.svg";
import { IClient, IStore } from "../../storeTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  clients: IClient[];
  getClients: (token: string, auth: boolean) => void;
  history: any;
}

const Clients: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  clients,
  getClients,
  history,
}) => {
  useEffect(() => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) => getClients(token, auth)
    );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    getClients,
  ]);

  return (
    <>
      <header className="Clients__header">
        <h2 className="Heading">Clients</h2>

        <div className="Clients__header__filter">
          <div className="Search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search for clients"
              className="Search__input"
            />
            <img className="Search__icon" src={searchIcon} alt="Search icon" />
          </div>

          <Link to="/rec/add-client">
            <button className="btn btn-green">Add new client</button>
          </Link>

          <div className="Sort">
            <span>Sort by</span>
            <select name="sort" id="sort">
              <option value=""></option>
              <option value="name">Name</option>
              <option value="nationalID">National ID</option>
              <option value="state">State</option>
              <option value="number">Number</option>
            </select>
          </div>
        </div>
      </header>

      <main className="Clients__table">
        {loading ? (
          <Loading />
        ) : (
          <Table
            header={["Client name", "National ID", "State", "Mobile number"]}
            rows={clients.map((client) => ({
              name: client.name,
              nationalId: client.nationalId,
              state: client.state,
              phoneNumber: client.phoneNumber,
            }))}
            link={clients.map(client => `/rec/client/${client.email}`)}
          />
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  clients: state.clients.clients,
});

const mapDispatchToProps = (dispatch: any) => ({
  getClients: (token: string, auth: boolean) =>
    dispatch({ type: actionTypes.GET_ALL_CLIENTS_SAGA, token, auth }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
