import React from "react";
import Loading from "./Loading";

interface Props {
  progress: number;
  loading: boolean;
  link?: boolean;
  onChange?: (progress: number) => void;
}

const WorkProgress: React.FC<Props> = ({
  progress,
  loading,
  link = false,
  onChange,
}) => {
  return link && onChange ? (
    <div className="Work-progress">
      <button
        onClick={() => onChange(0)}
        className={`${progress >= 0 && "done"}`}
      >
        Check up
      </button>
      <button
        onClick={() => onChange(2)}
        className={`${progress > 0 && "done"}`}
      >
        Get Parts
      </button>
      <button
        onClick={() => onChange(4)}
        className={`${progress > 3 && "done"}`}
      >
        Maintenance
      </button>
      <button
        onClick={() => onChange(5)}
        className={`${progress > 4 && "done"}`}
      >
        Car test
      </button>
      <button
        onClick={() => onChange(6)}
        className={`${progress > 5 && "done"}`}
      >
        Finshed
      </button>
    </div>
  ) : loading ? (
    <Loading />
  ) : (
    <div className="Work-progress">
      <span className={`${progress > 0 && "done"}`}>Check up</span>
      <span className={`${progress > 1 && "done"}`}>Get Parts</span>
      <span className={`${progress > 2 && "done"}`}>Maintenance</span>
      <span className={`${progress > 3 && "done"}`}>Car test</span>
      <span className={`${progress > 4 && "done"}`}>Finshed</span>
    </div>
  );
};

export default WorkProgress;
