import React from "react";
import Table from "../../components/Table";
import searchIcon from "../../images/search.svg";

interface Props {}

const cards = [
  {
    name: "Ahmed Mostafa",
    available: "Yes",
  },
  {
    name: "Ahmed Mostafa",
    available: "Yes",
  },
  {
    name: "Ahmed Mostafa",
    available: "No",
  },
  {
    name: "Ahmed Mostafa",
    available: "Yes",
  },
  {
    name: "Ahmed Mostafa",
    available: "No",
  },
  {
    name: "Ahmed Mostafa",
    available: "No",
  },
];

const EngMech: React.FC<Props> = () => {
  return (
    <>
      <header className="Cards__header">
        <h2 className="Heading">Mechanics</h2>

        <div className="Cards__header__filter">
          <div className="Search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search for clients"
              className="Search__input"
            />
            <img className="Search__icon" src={searchIcon} alt="Search icon" />
          </div>

          <div className="Sort">
            <span>Sort by</span>
            <select name="sort" id="sort">
              <option value=""></option>
              <option value="name">Mechanic name</option>
              <option value="available">Available</option>
            </select>
          </div>
        </div>
      </header>

      <main className="Cards__table">
        <Table
          header={["Mechanic name", "Available"]}
          rows={cards}
          link={[
            "/eng/mech/1",
            "/eng/mech/1",
            "/eng/mech/1",
            "/eng/mech/1",
            "/eng/mech/1",
            "/eng/mech/1",
          ]}
        />
      </main>
    </>
  );
};

export default EngMech;
