import jwt_decode from "jwt-decode";
import { actionTypes } from "../actionTypes";
import { IUser } from "../storeTypes";

interface Iaction {
  type: string;
  isFailed: boolean;
  payload: any;
  errors: any;
}

const loading = (state: IUser, action: Iaction) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
    case actionTypes.REFRESH_TOKEN:
      return {
        ...state,
        token: action.isFailed ? state.token : action.payload.token,
        expiration: action.isFailed
          ? state.expiration
          : action.payload.expiration,
        refreshToken: action.isFailed
          ? state.refreshToken
          : action.payload.refreshToken,
        refreshTokenExpiration: action.isFailed
          ? state.refreshTokenExpiration
          : action.payload.refreshTokenExpiration,
        name: action.isFailed
          ? state.name
          : (jwt_decode(action.payload.token) as any).Name,
        email: action.isFailed
          ? state.email
          : (jwt_decode(action.payload.token) as any).Email,
        userType: action.isFailed
          ? state.userType
          : (jwt_decode(action.payload.token) as any).UserType,
        errors: !action.isFailed ? {} : action.errors,
      };
    default:
      return {
        ...state,
        errors: [],
      };
  }
};

export default loading;
