const checkToken = (
  token: string,
  expiration: string,
  refreshToken: string,
  refreshTokenExpiration: string
): { token: string; refreshToken: string } => {
  if (!token) {
    return {
      token: "",
      refreshToken: "",
    };
  }

  const expirationDate = new Date(expiration);
  const refreshTokenExpirationDate = new Date(refreshTokenExpiration);

  if (expirationDate.getTime() < Date.now()) {
    if (refreshTokenExpirationDate.getTime() < Date.now()) {
      return {
        token: "",
        refreshToken: "",
      };
    } else {
      return {
        token: "",
        refreshToken,
      };
    }
  } else {
    return {
      token,
      refreshToken,
    };
  }
};

export default checkToken;
