import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import carDefault from "../../images/default-car.svg";
import backIcon from "../../images/back.svg";
import InfoCard from "../../components/InfoCard";
import { ICar, IStore } from "../../storeTypes";
import { actionTypes } from "../../actionTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";
import Loading from "../../components/Loading";

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  car: ICar;
  getCar: (token: string, auth: boolean, id: string) => void;
  history: any;
  match: any;
}

const CarInfo: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  car,
  getCar,
  history,
  match,
}) => {
  const [carData, setCarData] = useState<
    { key: string; value: string; img: string }[][]
  >([]);

  useEffect(() => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) => getCar(token, auth, match.params.carId)
    );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    match,
    getCar,
  ]);

  useEffect(() => {
    setCarData([
      [
        {
          key: "Plate number",
          value: car.plateNumber,
          img: "",
        },
        {
          key: "Kilo meter",
          value: car.kilometer,
          img: "",
        },
      ],
      [
        {
          key: "Type",
          value: car.type,
          img: "",
        },
        {
          key: "Model",
          value: car.model,
          img: "",
        },
        {
          key: "Size",
          value: car.size,
          img: "",
        },
      ],
      [
        {
          key: "Structure No",
          value: car.structureNo,
          img: "",
        },
      ],
    ]);
  }, [car]);

  return (
    <main>
      <Link to={`/rec/client/${match.params.clientEmail}`} className="Car__back">
        <img src={backIcon} alt="Back icon" />
        Back
      </Link>

      {/* Car information */}
      <section className="Car__info">
        <h2 className="Heading">Car information</h2>
        {loading ? (
          <Loading />
        ) : (
          <div className="Car__info__card">
            <img
              src={carDefault}
              alt="User avatar"
              className="Car__info__card__avatar"
            />

            <InfoCard info={carData} />

            <Link
              to={`/rec/add-car/${match.params.clientEmail}/${car.id}`}
              className="btn btn-green"
            >
              Edit car
            </Link>
          </div>
        )}
      </section>

      {/* Maintenance cards */}
      <section className="Car__cards">
        <header>
          <h2 className="Heading">Maintenance cards</h2>
        </header>

        {loading ? (
          <Loading />
        ) : (
          <Table
            header={["Card ID", "Date in", "Department", "Status"]}
            rows={car.maintenanceCards.map(
              ({ id, dateCreated, department, status }) => ({
                id,
                dateCreated,
                department,
                status,
              })
            )}
            link={car.maintenanceCards.map((card) => `/rec/card/${card.id}`)}
          />
        )}
      </section>
    </main>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  car: state.car.car,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCar: (token: string, auth: boolean, carId: string) =>
    dispatch({ type: actionTypes.GET_CAR_SAGA, token, auth, carId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarInfo);
