import { actionTypes } from "../actionTypes";
import { ICards } from "../storeTypes";

interface Iaction {
  type: string;
  isFailed: boolean;
  payload: any;
  errors: any;
}

const cards = (state: ICards, action: Iaction) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CARDS:
      return {
        ...state,
        cards: action.isFailed ? state.cards : action.payload,
        errors: !action.isFailed ? [] : action.errors,
      };
    case actionTypes.GET_CARD:
      return {
        ...state,
        card: action.isFailed ? state.card : action.payload,
        errors: !action.isFailed ? [] : action.errors,
      };
    case actionTypes.ADD_CARD:
      return {
        ...state,
        success: action.isFailed ? {} : { addCard: true },
        errors: !action.isFailed ? [] : action.errors,
      };
    default:
      return {
        ...state,
        success: {},
        errors: [],
      };
  }
};

export default cards;
