// import React from "react";
import welcomeImage from "../../images/welcome.svg";
import clientsIcon from "../../images/clients.svg";
import operCardsIcon from "../../images/open-cards.svg";
import waitingCardsIcon from "../../images/waiting-cards.svg";
import allCardsIcon from "../../images/document-active.svg";
import Table from "../../components/Table";

interface Props {}

const cards = [
  {
    id: "15236",
    plateNumber: "ABV 693",
    status: "Open",
  },
  {
    id: "15236",
    plateNumber: "ABV 693",
    status: "Closed",
  },
  {
    id: "15236",
    plateNumber: "ABV 693",
    status: "Closed",
  },
  {
    id: "15236",
    plateNumber: "ABV 693",
    status: "Closed",
  },
];

const Home = (props: Props) => {
  return (
    <>
      <header className="Home__header">
        <div className="Home__header__welcome">
          <h1>
            Welcome back engineer, <span>Ahmed Mostafa!</span>
          </h1>
          <p>Work hard, your job is your life</p>
        </div>
        <img src={welcomeImage} alt="Welcome" />
      </header>

      <main>
        {/* Overview */}
        <section className="Home__overview">
          <h2 className="Heading">Overview</h2>

          <div className="Home__overview__cards">
            {/* Clients Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--clients">
                <img src={clientsIcon} alt="Clients icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>Mechanic</h3>
                <p>100</p>
              </div>
            </div>
            {/* Open cards Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--open">
                <img src={operCardsIcon} alt="Open cards icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>Open cards</h3>
                <p>35</p>
              </div>
            </div>
            {/* Waiting cards Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--waiting">
                <img src={waitingCardsIcon} alt="Waiting cards icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>Waiting cards</h3>
                <p>65</p>
              </div>
            </div>
            {/* All cards Card */}
            <div className="Home__overview__cards__card">
              <div className="Home__overview__cards__card__icon Home__overview__cards__card__icon--all">
                <img src={allCardsIcon} alt="All cards icon" />
              </div>

              <div className="Home__overview__cards__card__details">
                <h3>All cards</h3>
                <p>696</p>
              </div>
            </div>
          </div>
        </section>

        {/* Tables */}
        <div className="Home__tables">
          {/* Cards */}
          <section className="Home__tables__table Home__tables__table--cards Home__tables__table Home__tables__table--cards--eng">
            <h2 className="Heading">Recent cards</h2>

            <Table
              header={["Card ID", "Plate number", "Status"]}
              rows={cards}
            />
          </section>
        </div>
      </main>
    </>
  );
};

export default Home;
