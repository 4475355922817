enum registerActionTypes {
  LOGIN_USER_SAGA = "LOGIN_USER_SAGA",
  LOGIN_USER = "LOGIN_USER",
  REFRESH_TOKEN_SAGA = "REFRESH_TOKEN_SAGA",
  REFRESH_TOKEN = "REFRESH_TOKEN"
}

export default registerActionTypes;

export interface LoginAction {
  type: registerActionTypes.LOGIN_USER_SAGA;
  data: {
    email: string;
    password: string;
  }
}

export interface RefreshTokenAction {
  type: registerActionTypes.REFRESH_TOKEN_SAGA;
  token: string;
}