import { takeEvery } from "redux-saga/effects";
import { actionTypes, clientsActions } from "../actionTypes";
import { helperSaga } from "./mainSaga";

function* GetClients({ token, auth }: clientsActions.GetClientsAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.GET_ALL_CLIENTS,
    type: "get",
    url: "Accounts/Clients",
  });
}

function* AddClient({ token, auth, data }: clientsActions.AddCLientAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.ADD_CLIENT,
    type: "post",
    url: "Accounts/Clients",
    data,
  });
}

function* GetClient({ token, auth, email }: clientsActions.GetClientAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.GET_CLIENT,
    type: "get",
    url: `Accounts/Clients/${email}`,
  });
}

function* EditClient({
  token,
  auth,
  data,
  email,
}: clientsActions.EditClientAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.EDIT_CLIENT,
    type: "put",
    url: `Accounts/Clients/${email}`,
    data,
  });
}

export function* watchClientsSaga() {
  yield takeEvery(actionTypes.GET_ALL_CLIENTS_SAGA, GetClients);
  yield takeEvery(actionTypes.ADD_CLIENT_SAGA, AddClient);
  yield takeEvery(actionTypes.GET_CLIENT_SAGA, GetClient);
  yield takeEvery(actionTypes.EDIT_CLIENT_SAGA, EditClient);
}
