import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actionTypes } from "../../actionTypes";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import searchIcon from "../../images/search.svg";
import { IStore } from "../../storeTypes";
import refreshTokenFunc from "../../utils/refreshTokenFunc";

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  cards: {
    id: number;
    dateOut: string;
    department: string;
    status: string;
    dateCreated: string;
    plateNumber: string;
  }[];
  getCards: (token: string, auth: boolean) => void;
  history: any;
}

const MaintenanceCards: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  cards,
  getCards,
  history,
}) => {
  useEffect(() => {
    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) => getCards(token, auth)
    );
  }, [
    token,
    expiration,
    refreshToken,
    refreshTokenExpiration,
    history,
    getCards,
  ]);

  return (
    <>
      <header className="Cards__header">
        <h2 className="Heading">Maintenance cards</h2>

        <div className="Cards__header__filter">
          <div className="Search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search for clients"
              className="Search__input"
            />
            <img className="Search__icon" src={searchIcon} alt="Search icon" />
          </div>

          <div className="Sort">
            <span>Sort by</span>
            <select name="sort" id="sort">
              <option value=""></option>
              <option value="id">Card ID</option>
              <option value="number">Plate number</option>
              <option value="date-in">Date in</option>
              <option value="date-out">Date out</option>
              <option value="department">Department</option>
              <option value="status">Status</option>
            </select>
          </div>
        </div>
      </header>

      <main className="Cards__table">
        {loading ? (
          <Loading />
        ) : (
          <Table
            header={[
              "Card ID",
              "Plate number",
              "Date in",
              "Date out",
              "Department",
              "Status",
            ]}
            rows={cards.map(
              ({
                id,
                plateNumber,
                dateCreated,
                dateOut,
                department,
                status,
              }) => ({
                id,
                plateNumber,
                dateCreated,
                dateOut,
                department,
                status,
              })
            )}
            link={cards.map((card) => `/rec/card/${card.id}`)}
          />
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  cards: state.cards.cards,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCards: (token: string, auth: boolean) =>
    dispatch({ type: actionTypes.GET_ALL_CARDS_SAGA, token, auth }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceCards);
