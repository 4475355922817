import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RegisterSideImg from "../images/login.png";
import Logo from "../images/logo-white.svg";

interface Props {}

const ForgetPassword = (props: Props) => {
  const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required("You must type your email")
      .email("You must type a vaild email address"),
  });

  return (
    <div className="Register">
      <img src={RegisterSideImg} alt="Register" className="Register__img" />
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(data) => console.log(data)}
        validationSchema={forgetPasswordSchema}
      >
        {({ errors, touched }) => (
          <Form className="Register__form">
            <img src={Logo} alt="Logo" className="Register__form__logo" />
            <h1 className="Heading">Reset Password</h1>
            <div className="Register__form__group">
              <label htmlFor="email">Email</label>
              <Field id="email" type="email" name="email" />
              {errors.email && touched.email && (
                <p className="Register__form__group__error">{errors.email}</p>
              )}
            </div>
            <div className="Register__form__submit">
              <button className="btn btn-blue" type="submit">
                Reset password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgetPassword;
