import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import CardInformation from "../../components/CardInformation";
import MechEngChat from "../../components/MechEngChat";
import RepairNotes from "../../components/RepairNotes";
import WorkProgress from "../../components/WorkProgress";
import SendNote from "../../images/send-dark.svg";
import { Link } from "react-router-dom";
import RepairParts from "../../components/RepairParts";

interface Props {}

// 0 => Checkup
// 1 => Get parts => Request parts
// 2 => Get parts => Waiting
// 3 => Get parts => Confirmed
// 4 => Maintenance
// 5 => Car test
// 6 => Finished

const repairNotes = [
  {
    name: "Start maintenance",
    date: "5:30 PM - 22 Mar, 2021",
  },
  {
    name: "Remove old part",
    date: "5:30 PM - 22 Mar, 2021",
  },
  {
    name: "Add the new part Four gray shock absorbers, Car Exhaust system Spark plug",
    date: "5:30 PM - 22 Mar, 2021",
  },
  {
    name: "Finished add new parts",
    date: "5:30 PM - 22 Mar, 2021",
  },
];

const parts = [
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
  {
    name: "Four gray shock absorbers, Car Exhaust system Spark plug",
    picture: "https://via.placeholder.com/40",
  },
];

const chat = [
  {
    send: true,
    text: "I'll take 30 minutes a break for lunch",
    time: "5:30 PM - 22 Mar, 2021",
  },
  {
    send: false,
    text: "I'll take 30 minutes a break for lunch",
    time: "5:30 PM - 22 Mar, 2021",
  },
  {
    send: true,
    text: "I'll take 30 minutes a break for lunch I'll take 30 minutes a break for lunch",
    time: "5:30 PM - 22 Mar, 2021",
  },
];

const MechCard = (props: Props) => {
  const [section, setSection] = useState<"info" | "history">("history");
  const [progress, setProgress] = useState<number>(0);

  const addNoteSchema = Yup.object().shape({
    note: Yup.string().required("You must type your repair note"),
  });

  return (
    <>
      <header className="Card__header">
        <h1>Maintenance card #15236</h1>
      </header>

      <main>
        <div className="Card__btns">
          <button
            className={section === "history" ? "active" : ""}
            onClick={() => setSection("history")}
          >
            Work history
          </button>
          <button
            className={section === "info" ? "active" : ""}
            onClick={() => setSection("info")}
          >
            Card Information
          </button>
        </div>

        <section className="Card__section">
          {section === "info" ? (
            <CardInformation
              loading={false}
              card={{
                id: 0,
                notes: "",
                department: "",
                status: "",
                dateOut: "",
                dateCreated: "",
                pictures: [],
                steps: [],
                car: {
                  id: 0,
                  color: "",
                  kilometer: "",
                  model: "",
                  plateNumber: "",
                  size: "",
                  structureNo: "",
                  type: "",
                },
                client: {
                  address: "",
                  email: "",
                  name: "",
                  nationalId: "",
                  phoneNumber: "",
                  profilePicture: "",
                  state: "",
                  whatsApp: "",
                },
              }}
            />
          ) : (
            <>
              <WorkProgress progress={progress} loading={false} />
              <div className="Card__section__content">
                {progress === 0 || progress === 4 || progress === 5 ? (
                  <div className="Card__section__content__notes">
                    <RepairNotes notes={repairNotes} />
                    <Formik
                      initialValues={{ note: "" }}
                      onSubmit={(data) => console.log(data)}
                      validationSchema={addNoteSchema}
                    >
                      {({ errors, touched }) => (
                        <Form className="Card__section__content__notes__form">
                          <div>
                            <Field
                              id="note"
                              name="note"
                              placeholder="Type here repair notes..."
                            />{" "}
                            <button type="submit">
                              <img src={SendNote} alt="Send note" />
                            </button>
                          </div>
                          {errors.note && touched.note && (
                            <p className="error">{errors.note}</p>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                ) : progress === 1 ? (
                  <Link to="/mech/select-parts" className="btn btn-red">
                    Request parts
                  </Link>
                ) : progress === 6 ? (
                  <span className="btn btn-green Card__section__content__finish">
                    Finish maintenance card
                  </span>
                ) : progress === 2 || progress === 3 ? (
                  <div className="Card__section__content__parts">
                    <RepairParts progress={progress} parts={parts} />
                    {progress === 2 && <Link to="/">+ Add more parts</Link>}
                  </div>
                ) : undefined}
                <div className="Card__section__content__chat">
                  <MechEngChat chat={chat} />
                  <div className="Card__section__content__chat__controls">
                    {(progress === 1 || progress === 4 || progress === 5) && (
                      <button
                        className="btn btn-grey"
                        onClick={() => setProgress(progress - 1)}
                      >
                        Back
                      </button>
                    )}
                    {progress < 6 && (
                      <button
                        className="btn btn-blue"
                        onClick={() => setProgress(progress + 1)}
                        // disabled={progress === 1}
                      >
                        Next step
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </main>
    </>
  );
};

export default MechCard;
