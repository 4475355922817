import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import addVideo from "../../images/plus.svg";
import { IStore } from "../../storeTypes";
import { actionTypes } from "../../actionTypes";
import Loading from "../../components/Loading";
import refreshTokenFunc from "../../utils/refreshTokenFunc";

interface Props {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  loading: boolean;
  success: any;
  addCard: (
    token: string,
    auth: boolean,
    data: {
      notes: string;
      department?: number;
      pictures?: FileList[];
      plateNumber: string;
    }
  ) => void;
  history: any;
}

const CreateCard: React.FC<Props> = ({
  token,
  expiration,
  refreshToken,
  refreshTokenExpiration,
  loading,
  success,
  addCard,
  history,
}) => {
  const [pictures, setPictures] = useState<string[]>([""]);
  const [files, setFiles] = useState<FileList[]>([]);

  useEffect(() => {
    if (success.addCard) history.push(`/rec/cards`);
  }, [success, history]);

  const creatCardSchema = Yup.object().shape({
    plateNumber: Yup.string().required("You must choose car"),
    department: Yup.number()
      .required("You must choose department")
      .positive("You must choose department"),
    notes: Yup.string().required("You must type your notes"),
  });

  const submitForm = ({
    plateNumber,
    department,
    notes,
  }: {
    plateNumber: string;
    department: number;
    notes: string;
  }) => {
    const data = { plateNumber, department: department - 1, notes, pictures: files.filter(file => file) };

    refreshTokenFunc(
      token,
      expiration,
      refreshToken,
      refreshTokenExpiration,
      history,
      () => {},
      ["Receptionist"],
      (token: string, auth: boolean) => addCard(token, auth, data)
    );
  };

  return (
    <>
      <header>
        <h1 className="Form__heading">Create new card</h1>
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{
              plateNumber: "",
              department: 0,
              notes: "",
              videos: [""],
            }}
            onSubmit={submitForm}
            validationSchema={creatCardSchema}
          >
            {({ errors, touched, values }) => (
              <Form className="Form__container">
                <h2 className="Form__container__heading">
                  Repair requirements
                </h2>
                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="plateNumber">Select car</label>
                    <Field id="plateNumber" name="plateNumber" as="select">
                      <option value="" disabled></option>
                      <option value="ASD12">ASD12</option>
                      <option value="Makkah">Makkah</option>
                      <option value="Dmam">Dmam</option>
                    </Field>
                  </div>
                  <div className="Form__container__row__input">
                    <label htmlFor="department">Department</label>
                    <Field id="department" name="department" as="select">
                      <option value={0} disabled></option>
                      <option value={1}>Mechanic</option>
                      <option value={2}>Makkah</option>
                      <option value={3}>Dmam</option>
                    </Field>
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="notes">Notes</label>
                    <Field id="notes" name="notes" as="textarea" />
                  </div>
                </div>

                <div className="Form__container__row">
                  <div className="Form__container__row__input">
                    <label htmlFor="videos">Car videos</label>
                    <FieldArray
                      name="videos"
                      render={(arrayHelpers) => (
                        <>
                          <div className="Form__container__row__input__videos">
                            {values.videos.map((video, i) => (
                              <React.Fragment key={i}>
                                <label
                                  htmlFor={`videos-${i}`}
                                  className={!video ? "empty" : ""}
                                >
                                  {!pictures[i] ? (
                                    <span>
                                      <img
                                        src={addVideo}
                                        alt="add Video icon"
                                      />{" "}
                                      Add Video
                                    </span>
                                  ) : (
                                    <video src={pictures[i] || ""} controls />
                                  )}
                                </label>
                                <Field
                                  id={`videos-${i}`}
                                  name={`videos[${i}]`}
                                  type="file"
                                  key={i}
                                  hidden
                                  accept="video/*"
                                  onChange={(e: any) => {
                                    if (
                                      e.currentTarget.files &&
                                      e.currentTarget.files[0]
                                    ) {
                                      let filesCopy = [...files];
                                      filesCopy[1] = e.currentTarget.files[0];
                                      setFiles(filesCopy);

                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        let picCopy = [...pictures];
                                        picCopy[i] = e.target?.result as string;
                                        setPictures(picCopy);
                                      };
                                      reader.readAsDataURL(
                                        e.currentTarget.files[0]
                                      );
                                    }
                                  }}
                                />
                              </React.Fragment>
                            ))}
                          </div>
                          <button
                            type="button"
                            onClick={() => arrayHelpers.push("")}
                            className="Form__container__row__input__add"
                          >
                            + Add more videos
                          </button>
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="Form__container__errors">
                  {errors.plateNumber && touched.plateNumber ? (
                    <p className="error">{errors.plateNumber}</p>
                  ) : null}
                  {errors.department && touched.department ? (
                    <p className="error">{errors.department}</p>
                  ) : null}
                </div>

                <button className="btn btn-red" type="submit">
                  Add new car
                </button>
              </Form>
            )}
          </Formik>
        )}
      </header>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  token: state.user.token,
  expiration: state.user.expiration,
  refreshToken: state.user.refreshToken,
  refreshTokenExpiration: state.user.refreshTokenExpiration,
  loading: state.loading,
  success: state.cards.success,
});

const mapDispatchToProps = (dispatch: any) => ({
  addCard: (
    token: string,
    auth: boolean,
    data: {
      notes: string;
      department?: number;
      pictures?: FileList[];
      plateNumber: string;
    }
  ) => dispatch({ type: actionTypes.ADD_CARD_SAGA, token, auth, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCard);
