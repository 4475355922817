import { actionTypes } from "../actionTypes";
import { ICar } from "../storeTypes";

interface Iaction {
  type: string;
  isFailed: boolean;
  payload: any;
  errors: any;
}

interface IState {
  car: ICar;
  success: {};
  errors: [];
}

const car = (state: IState, action: Iaction) => {
  switch (action.type) {
    case actionTypes.GET_CAR:
      return {
        ...state,
        success: {},
        car: action.isFailed ? state.car : action.payload,
        errors: !action.isFailed ? {} : action.errors,
      };
    case actionTypes.ADD_CAR:
    case actionTypes.EDIT_CAR:
      return {
        ...state,
        success: action.isFailed ? {} : { addCar: true },
        errors: !action.isFailed ? {} : action.errors,
      };
    default:
      return {
        ...state,
        success: {},
        errors: [],
      };
  }
};

export default car;
