import { takeEvery } from "redux-saga/effects";
import { actionTypes, carsActions } from "../actionTypes";
import { helperSaga } from "./mainSaga";

function* GetCar({ token, auth, carId }: carsActions.GetCarAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.GET_CAR,
    type: "get",
    url: `Cars/${carId}`,
    getResult: (data: any) => {
      const { dateCreated, dateModified, ...res } = data;
      return res;
    },
  });
}

function* AddCar({ token, auth, data }: carsActions.AddCarAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.ADD_CAR,
    type: "post",
    data,
    url: `Cars`,
  });
}

function* EditCar({ token, auth, data, id }: carsActions.EditCarAction) {
  yield helperSaga({
    token,
    auth,
    actionType: actionTypes.EDIT_CAR,
    type: "put",
    data,
    url: `Cars/${id}`,
  });
}

export function* watchCarSaga() {
  yield takeEvery(actionTypes.GET_CAR_SAGA, GetCar);
  yield takeEvery(actionTypes.ADD_CAR_SAGA, AddCar);
  yield takeEvery(actionTypes.EDIT_CAR_SAGA, EditCar);
}
