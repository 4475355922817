import React, { useEffect, useState } from "react";
import InfoCard from "./InfoCard";
import { ICard } from "../storeTypes";
import Loading from "./Loading";

interface Props {
  loading: boolean;
  card: ICard;
}

const CardInformation: React.FC<Props> = ({ card, loading }) => {
  const [clientInfo, setClientInfo] = useState<
    { key: string; value: string; img: string }[][]
  >([]);
  const [carData, setCarData] = useState<
    { key: string; value: string; img: string }[][]
  >([]);
  const [repairData, setRepairData] = useState<
    {
      key: string;
      value: string | string[];
      img: string;
      type?: string;
    }[][]
  >([]);

  useEffect(() => {
    setClientInfo([
      [
        {
          key: "Name",
          value: card.client.name,
          img: "",
        },
      ],
      [
        {
          key: "Address",
          value: card.client.address,
          img: "",
        },
        {
          key: "National ID",
          value: card.client.nationalId,
          img: "",
        },
      ],
      [
        {
          key: "Email",
          value: card.client.email,
          img: "",
        },
        {
          key: "Mobile Num",
          value: card.client.phoneNumber,
          img: "",
        },
        {
          key: "Whatsapp",
          value: card.client.whatsApp,
          img: "",
        },
      ],
    ]);
    setCarData([
      [
        {
          key: "Plate number",
          value: card.car.plateNumber,
          img: "",
        },
        {
          key: "Kilo meter",
          value: card.car.kilometer,
          img: "",
        },
        {
          key: "Type",
          value: card.car.type,
          img: "",
        },
        {
          key: "Model",
          value: card.car.model,
          img: "",
        },
      ],
      [
        {
          key: "Size",
          value: card.car.size,
          img: "",
        },
        {
          key: "Structure No",
          value: card.car.structureNo,
          img: "",
        },
      ],
    ]);
    setRepairData([
      [
        {
          key: "Department",
          value: card.department,
          img: "",
        },
        {
          key: "Date in",
          value: card.dateCreated,
          img: "",
        },
        {
          key: "Date out",
          value: card.dateOut,
          img: "",
        },
      ],
      [
        {
          key: "Client notes",
          value: card.notes,
          img: "",
          type: "paragraph",
        },
      ],
      [
        {
          key: "Car photos",
          value: card.pictures.map((pic) => pic.uri),
          img: "",
          type: "images",
        },
      ],
    ]);
  }, [card]);

  return (
    <div className="Card__info">
      <section className="Card__info__client">
        <header className="Card__info__client__header">
          <h2 className="Heading">Client information</h2>
          <span className={`Card__info__client__header__status status-${card.status}`}>
            {card.status}
          </span>
        </header>
        {loading ? <Loading /> : <InfoCard info={clientInfo} />}
      </section>

      <hr />

      <section>
        <header>
          <h2 className="Heading">Car information</h2>
        </header>
        {loading ? <Loading /> : <InfoCard info={carData} />}
      </section>

      <hr />

      <section>
        <header>
          <h2 className="Heading">Repair information</h2>
        </header>
        {loading ? <Loading /> : <InfoCard info={repairData} />}
      </section>
    </div>
  );
};

export default CardInformation;
