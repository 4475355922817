enum cardsActionTypes {
  GET_ALL_CARDS_SAGA = "GET_ALL_CARDS_SAGA",
  GET_ALL_CARDS = "GET_ALL_CARDS",
  ADD_CARD_SAGA = "ADD_CARD_SAGA",
  ADD_CARD = "ADD_CARD",
  GET_CARD_SAGA = "GET_CARD_SAGA",
  GET_CARD = "GET_CARD",
}

export default cardsActionTypes;

export interface GetCardsAction {
  type: cardsActionTypes.GET_ALL_CARDS_SAGA;
  token: string;
  auth: boolean;
}

export interface AddCardAction {
  type: cardsActionTypes.ADD_CARD_SAGA;
  token: string;
  auth: boolean;
  data: {
    notes: string;
    department?: number;
    pictures?: string[];
    plateNumber: string;
  };
}

export interface GetCardAction {
  type: cardsActionTypes.GET_CARD_SAGA;
  token: string;
  auth: boolean;
  cardId: number;
}
