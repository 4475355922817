import { combineReducers } from "redux";
import loading from "./loadingReducer";
import user from "./userReducer";
import clients from "./clientsReducer";
import cards from "./cardsReducer";
import car from "./carReducer";

export default combineReducers({
  loading,
  user,
  clients,
  cards,
  car
});
