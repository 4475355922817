import cardsActionTypes, * as cardsActions from "./actions/CardsActionTypes";
import carsActionTypes, * as carsActions from "./actions/CarsActionTypes";
import clientsActionTypes, * as clientsActions from "./actions/ClientsActionTypes";
import registerActionTypes, * as registerActions from "./actions/RegisterActionTypes";

export const actionTypes = {
  ...registerActionTypes,
  ...clientsActionTypes,
  ...cardsActionTypes,
  ...carsActionTypes,
  LOADING: "LOADING",
  API_ERROR: "API_ERROR"
};

export {
  registerActions,
  clientsActions,
  cardsActions,
  carsActions
};
