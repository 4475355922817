import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RegisterSideImg from "../images/login.png";
import Logo from "../images/logo-white.svg";

interface Props {}

const PasswordCode = (props: Props) => {
  const forgetPasswordSchema = Yup.object().shape({
    code: Yup.number()
      .required("You must type your code")
      .max(9999, "You must type a vaild code")
      .min(1000, "You must type a valid code"),
  });

  const inputKeyDownHandler = (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    target: number
  ) => {
    if (e.key === "Tab") return true;
    console.log(e.key);
    if (e.key !== "Backspace") e.preventDefault();
    e.currentTarget.value = e.key;
    setFieldValue(`code-${target + 1}`, e.currentTarget.value);
  };

  return (
    <div className="Register">
      <img src={RegisterSideImg} alt="Register" className="Register__img" />
      <Formik
        initialValues={{
          "code-1": 0,
          "code-2": 0,
          "code-3": 0,
          "code-4": 0,
        }}
        onSubmit={(data) => console.log(data)}
        validationSchema={forgetPasswordSchema}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="Register__form">
            <img src={Logo} alt="Logo" className="Register__form__logo" />
            <h1 className="Heading">Reset Password</h1>
            <p className="Register__form__desc">
              Please enter the reset code that was sent to the mobile number
              +9665*****018
            </p>
            <div className="Register__form__group Register__form__group--code">
              <Field
                id="code-1"
                type="number"
                name="code-1"
                onKeyDown={(e: any) =>
                  inputKeyDownHandler(e, setFieldValue, 0)
                }
              />
              <Field
                id="code-2"
                type="number"
                name="code-2"
                onKeyDown={(e: any) =>
                  inputKeyDownHandler(e, setFieldValue, 1)
                }
              />
              <Field
                id="code-3"
                type="number"
                name="code-3"
                onKeyDown={(e: any) =>
                  inputKeyDownHandler(e, setFieldValue, 2)
                }
              />
              <Field
                id="code-4"
                type="number"
                name="code-4"
                onKeyDown={(e: any) =>
                  inputKeyDownHandler(e, setFieldValue, 3)
                }
              />
              {/* {errors.code && touched.code && (
                <p className="Register__form__group__error">{errors.code}</p>
              )} */}
            </div>
            <div className="Register__form__submit">
              <button className="btn btn-blue" type="submit">
                Reset password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordCode;
