import React from "react";

interface Props {
  info: {
    key: string;
    value: string | string[];
    img: string;
    type?: string;
  }[][];
}

const InfoCard: React.FC<Props> = ({ info }) => {
  return (
    <div className="Client__info__card__details">
      {info.map((row, i) => (
        <div className="Client__info__card__details__row" key={i}>
          {row.map((prop, j) => (
            <div key={j}>
              <p>
                {prop.img ? <img src={prop.img} alt="Name icon" /> : undefined}
                {prop.key}
              </p>
              <div className={prop.type === "paragraph" ? prop.type : ""}>
                {prop.type === "images" ? (
                  <div className="Client__info__card__details__row__images">
                    {typeof prop.value !== "string" &&
                      prop.value.map((img, i) => (
                        <img src={img} key={i} alt="A screenshot of car" />
                      ))}
                  </div>
                ) : (
                  <span
                    className={`${prop.key === "Name" ? "name" : ""} ${
                      prop.img ? "ml" : ""
                    }`}
                  >
                    {prop.value}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default InfoCard;
