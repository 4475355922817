import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RegisterSideImg from "../images/login.png";
import Logo from "../images/logo-white.svg";

interface Props {}

const ChangePassword = (props: Props) => {
  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required("You must type your password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Two passwords must be identical")
      .required("You must retype your password"),
  });

  const passwordValidate = (password: string) => {
    let error;
    if (!password || password.length < 8)
      error = "Password must be more than 7 characters";
    else if (
      password.search(/[0-9]/) === -1 ||
      password.search(/[a-z]/) === -1 ||
      password.search(/[A-Z]/) === -1 ||
      password.search(/[^0-9a-zA-z]/) === -1
    )
      error =
        "Password must contain lower character, upper character and a symbol";
    else error = "";
    return error;
  };

  return (
    <div className="Register">
      <img src={RegisterSideImg} alt="Register" className="Register__img" />
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={(data) => console.log(data)}
        validationSchema={changePasswordSchema}
      >
        {({ errors, touched }) => (
          <Form className="Register__form">
            <img src={Logo} alt="Logo" className="Register__form__logo" />
            <h1 className="Heading">Reset Password</h1>
            <div className="Register__form__group">
              <label htmlFor="password">New Password</label>
              <Field
                id="password"
                type="password"
                name="password"
                validate={passwordValidate}
              />
              {errors.password && touched.password && (
                <p className="Register__form__group__error">
                  {errors.password}
                </p>
              )}
            </div>
            <div className="Register__form__group">
              <label htmlFor="confirmPassword">Verify new Password</label>
              <Field
                id="confirmPassword"
                type="password"
                name="confirmPassword"
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <p className="Register__form__group__error">
                  {errors.confirmPassword}
                </p>
              )}
            </div>
            <div className="Register__form__submit">
              <button className="btn btn-blue" type="submit">
                Reset password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
