import userDefault from "../../images/default-user.svg";
import userIcon from "../../images/profile.svg";
import locationIcon from "../../images/location.svg";
import nationalIDIcon from "../../images/id.svg";
import emailIcon from "../../images/email.svg";
import mobileIcon from "../../images/phone.svg";
import whatsappIcon from "../../images/whatsapp.svg";
import searchIcon from "../../images/search.svg";
import Table from "../../components/Table";
import InfoCard from "../../components/InfoCard";

interface Props {}

const cards = [
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Open",
  },
];

const archiveCards = [
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
  {
    id: "15236",
    number: "ABV 693",
    dateIn: "19 Mar, 2021",
    dateOut: "19 Mar, 2021",
    status: "Closed",
  },
];

const clientInfo: { key: string; value: string; img: string }[][] = [
  [
    {
      key: "Name",
      value: "Ahmed Mostafa",
      img: userIcon,
    },
  ],
  [
    {
      key: "Address",
      value: "RJ89+R7, King Fahd Branch Rd, Al Malqa, Riyadh",
      img: locationIcon,
    },
    {
      key: "National ID",
      value: "10 20 65 30",
      img: nationalIDIcon,
    },
  ],
  [
    {
      key: "Email",
      value: "name@domain.com",
      img: emailIcon,
    },
    {
      key: "Mobile Num",
      value: "50 6359 6210",
      img: mobileIcon,
    },
    {
      key: "Whatsapp",
      value: "+966 50 6359 6210",
      img: whatsappIcon,
    },
  ],
];

const EngMechCard = (props: Props) => {
  return (
    <main>
      {/* Client information */}
      <section className="Client__info">
        <h2 className="Heading">Mechanic information</h2>

        <div className="Client__info__card">
          <img
            src={userDefault}
            alt="User avatar"
            className="Client__info__card__avatar"
          />

          <InfoCard info={clientInfo} />
        </div>
      </section>

      {/* Open maintenance cards */}
      <section className="Client__cars">
        <header>
          <h2 className="Heading">Open maintenance cards</h2>
        </header>

        <Table
          header={["Card ID", "Plate number", "Date in", "Date out", "Status"]}
          rows={cards}
          link={["/eng/card/1"]}
        />
      </section>

      {/* Archive maintenance cards */}
      <section className="Client__cars">
        <div style={{ marginBottom: "16px" }}>
          <h2 className="Heading">Maintenance cards Archive</h2>

          <div className="Cards__header__filter">
            <div className="Search">
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search for clients"
                className="Search__input"
              />
              <img
                className="Search__icon"
                src={searchIcon}
                alt="Search icon"
              />
            </div>

            <div className="Sort">
              <span>Sort by</span>
              <select name="sort" id="sort">
                <option value=""></option>
                <option value="id">Card ID</option>
                <option value="number">Plate number</option>
                <option value="date-in">Date in</option>
                <option value="date-out">Date out</option>
                <option value="status">Status</option>
              </select>
            </div>
          </div>
        </div>

        <Table
          header={["Card ID", "Plate number", "Date in", "Date out", "Status"]}
          rows={archiveCards}
          link={[
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
            "/eng/card/1",
          ]}
        />

        <footer className="Client__cars__footer">
          <p>Showing 10 of 102 cards</p>
          <div className="Client__cars__footer__pages">
            <button className="btn btn-grey">Previous</button>
            <button className="btn btn-blue">Next</button>
          </div>
        </footer>
      </section>
    </main>
  );
};

export default EngMechCard;
