import React from "react";
import repairNoteIcon from "../images/mechanic-tools.svg";
import timeIcon from "../images/clock-red.svg";

interface Props {
  notes: {
    name: string;
    date: string;
  }[];
}

const RepairNotes: React.FC<Props> = ({ notes }) => {
  return (
    <>
      <h2 className="Heading">Repair notes</h2>
      <div className="Card__section__content__notes__box">
        {notes.map((note, i) => (
          <div className="Card__section__content__notes__note" key={i}>
            <img src={repairNoteIcon} alt="Repair note icon" />
            <p className="Card__section__content__notes__note__text">
              {note.name}
            </p>
            <span className="Card__section__content__notes__note__date">
              <img src={timeIcon} alt="time icon" /> {note.date}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default RepairNotes;
