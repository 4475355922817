import { all } from "redux-saga/effects";
import { watchCardssSaga } from "./cardsSaga";
import { watchCarSaga } from "./carSaga";
import { watchClientsSaga } from "./clientsSaga";
import { watchRegisterUser } from "./regsisterSaga";

export default function* rootSaga() {
  yield all([
    watchRegisterUser(),
    watchClientsSaga(),
    watchCardssSaga(),
    watchCarSaga(),
  ]);
}
