// import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
// import { actionTypes } from "../actionTypes";
// import invokedConnections from "../signalr/InvokeConnections";
// import ReceiveConversationsConnctions from "../signalR/ReceiveConversations";
// import ReceiveMsgsConnctions from "../signalR/ReceiveMsg";
// import ReceiveNotificationsConnctions from "../signalR/ReceiveNotifications";
// import refreshTokenFunc from "./refreshToken";

// const startSignalRConnection = (
//   connection: HubConnection,
//   action: any,
//   dispatch: any,
//   getState: any
// ) =>
//   connection
//     .start()
//     .then(() => {
//       // dispatch({ type: actionTypes.SIGNALR_LOADING, payload: false });
//       // SUB
//       // ReceiveConversationsConnctions(connection, dispatch);
//       // ReceiveMsgsConnctions(connection, dispatch, getState);
//       // ReceiveNotificationsConnctions(connection, dispatch);
//       if (action) {
//         // PUB
//         invokedConnections(connection, action, dispatch);
//       }
//     })
//     .catch((err: any) => console.error("SignalR Connection Error: ", err));

// let connection: HubConnection;

const signalRMiddleware =
  ({ getState, dispatch }: { getState: any; dispatch: any }) =>
  (next: any) =>
  (action: any) => {
    // let options;

    // if (action && action.type === actionTypes.REFRESH_TOKEN_SAGA)
    //   return next(action);

    // if (connection && action.type !== actionTypes.REFRESH_TOKEN) {
    //   if (connection.state === "Connected")
    //     invokedConnections(connection, action, dispatch);
    //   return next(action);
    // }

    // if (
    //   action &&
    //   action.type === actionTypes.LOGIN_USER
    // ) {
    //   options = {
    //     accessTokenFactory: () => action.payload.token,
    //   };
    // } else if (getState().user.token) {
    //   refreshTokenFunc(
    //     getState().user.token,
    //     getState().user.expiration,
    //     getState().user.refreshToken,
    //     getState().user.refreshTokenExpiration,
    //     undefined,
    //     (token: string) =>
    //       dispatch({
    //         type: actionTypes.REFRESH_TOKEN_SAGA,
    //         token,
    //       }),
    //     ["Participant", "Company", "Employee", "Adminstrator", "Developer"]
    //   );

    //   options = {
    //     accessTokenFactory: () => getState().user.token,
    //   };
    // } else {
    //   return next(action);
    // }

    // if (options) {
    //   connection = new HubConnectionBuilder()
    //     .withUrl("https://thevgate.azurewebsites.net/realtime", options)
    //     .build();

    //   dispatch({ type: actionTypes.SIGNALR_LOADING, payload: true });

    //   connection.onclose(() =>
    //     setTimeout(
    //       () => startSignalRConnection(connection, action, dispatch, getState),
    //       5000
    //     )
    //   );

    //   startSignalRConnection(connection, action, dispatch, getState);
    // }

    return next(action);
  };

export default signalRMiddleware;
