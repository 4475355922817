enum clientsActionTypes {
  GET_ALL_CLIENTS_SAGA = "GET_ALL_CLIENTS_SAGA",
  GET_ALL_CLIENTS = "GET_ALL_CLIENTS",
  ADD_CLIENT_SAGA = "ADD_CLIENT_SAGA",
  ADD_CLIENT = "ADD_CLIENT",
  GET_CLIENT_SAGA = "GET_CLIENT_SAGA",
  GET_CLIENT = "GET_CLIENT",
  EDIT_CLIENT_SAGA = "EDIT_CLIENT_SAGA",
  EDIT_CLIENT = "EDIT_CLIENT"
}

export default clientsActionTypes;

export interface GetClientsAction {
  type: clientsActionTypes.GET_ALL_CLIENTS_SAGA;
  token: string;
  auth: boolean;
}

export interface AddCLientAction {
  type: clientsActionTypes.ADD_CLIENT_SAGA;
  token: string;
  auth: boolean;
  data: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    whatsApp: string;
    address: string;
    state: string;
    nationalId: string;
  };
}

export interface GetClientAction {
  type: clientsActionTypes.GET_CLIENT_SAGA;
  token: string;
  auth: boolean;
  email: string;
}

export interface EditClientAction {
  type: clientsActionTypes.EDIT_CLIENT_SAGA;
  token: string;
  auth: boolean;
  data: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    whatsApp?: string;
    address?: string;
    state?: string;
    nationalId?: string;
  };
  email: string;
}