import React from 'react'
import qrCode from "../../images/qr.png"

interface Props {
  
}

const MechHome = (props: Props) => {
  return (
    <main className="MechHome__qr Mech">
      <img src={qrCode} alt="QR code" />
      <button className="btn btn-red">Scan QR Code</button>
    </main>
  )
}

export default MechHome
