import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// REC
import addCar from "../containers/Rec/addCar";
import addClient from "../containers/Rec/addClient";
import CardInfo from "../containers/Rec/CardInfo";
import CarInfo from "../containers/Rec/CarInfo";
import ClientInfo from "../containers/Rec/ClientInfo";
import Clients from "../containers/Rec/Clients";
import CreateCard from "../containers/Rec/CreateCard";
import RecHome from "../containers/Rec/Home";
import MaintenanceCards from "../containers/Rec/MaintenanceCards";
// Navbars
import RecSideNav from "./Navbars/RecSideNav";
import RecTopNav from "./Navbars/RecTopNav";
import MechTopNav from "./Navbars/MechTopNav";
import EngSideNav from "./Navbars/EngSideNav";
import EngTopNav from "./Navbars/EngTopNav";
// Mech
import MechHome from "../containers/Mech/Home";
import MechMaintenanceCards from "../containers/Mech/MaintenanceCards";
import MechCard from "../containers/Mech/Card";
import SelectParts from "../containers/Mech/SelectParts";
// Eng
import EngHome from "../containers/Eng/Home";
import EngMaintenanceCards from "../containers/Eng/MaintenanceCards";
import EngMech from "../containers/Eng/Mech";
import EngMechCard from "../containers/Eng/MechCard";
import EngCard from "../containers/Eng/CardInfo";
// Login
import Login from "../containers/Login";
import ForgetPassword from "../containers/ForgetPassword";
import PasswordCode from "../containers/PasswordCode";
import ChangePassword from "../containers/ChangePassword";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Rec */}
        <Route exact path="/rec/add-:page/:query?/:query2?">
          <div className="Body">
            <RecSideNav />
            <div className="Body__container">
              <Route exact path="/rec/add-client" component={addClient} />
              <Route exact path="/rec/add-client/:email" component={addClient} />
              <Route exact path="/rec/add-car/:clientEmail" component={addCar} />
              <Route exact path="/rec/add-car/:clientEmail/:id" component={addCar} />
              <Route exact path="/rec/add-card" component={CreateCard} />
            </div>
          </div>
        </Route>
        <Route exact path="/rec/:page?/:id?/:rest?">
          <div className="Body">
            <RecSideNav />
            <div className="Body__container">
              <RecTopNav />
              <Route exact path="/rec/" component={RecHome} />
              <Route exact path="/rec/clients" component={Clients} />
              <Route exact path="/rec/cards" component={MaintenanceCards} />
              <Route exact path="/rec/client/:email" component={ClientInfo} />
              <Route
                exact
                path="/rec/car/:clientEmail/:carId"
                component={CarInfo}
              />
              <Route exact path="/rec/card/:id" component={CardInfo} />
            </div>
          </div>
        </Route>
        {/* Mec */}
        <Route exact path="/mech/:page?/:id?">
          <div className="Body">
            <MechTopNav />
            <Route exact path="/mech" component={MechHome} />
            <Route exact path="/mech/cards" component={MechMaintenanceCards} />
            <Route exact path="/mech/card/:id" component={MechCard} />
            <Route exact path="/mech/select-parts" component={SelectParts} />
          </div>
        </Route>
        {/* Eng */}
        <Route exact path="/eng/card/:id">
          <div className="Body">
            <EngTopNav />
            <Route exact path="/eng/card/:id" component={EngCard} />
          </div>
        </Route>
        <Route exact path="/eng/:page?/:id?">
          <div className="Body">
            <EngSideNav />
            <div className="Body__container">
              <EngTopNav />
              <Route exact path="/eng/" component={EngHome} />
              <Route exact path="/eng/cards" component={EngMaintenanceCards} />
              <Route exact path="/eng/mechs" component={EngMech} />
              <Route exact path="/eng/mech/:id" component={EngMechCard} />
            </div>
          </div>
        </Route>
        {/* Login */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/code" component={PasswordCode} />
        <Route exact path="/change-password" component={ChangePassword} />
        {/* ............. */}
        <Redirect to="/Login" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
