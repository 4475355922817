// import React from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../images/logo.svg";
import scanIcon from "../../images/scan.svg";
import scanActiveIcon from "../../images/scan-active.svg";
import documentIcon from "../../images/document.svg";
import documentActiveIcon from "../../images/document-active.svg";
import logoutIcon from "../../images/logout.svg";

interface Props {}

const MechTopNav = (props: Props) => {
  const page = (useParams() as { page: string }).page;
  const active = !page ? "QR" : "Cards";

  return (
    <nav className="Mechnav">
      <Link to="/" className="Mechnav__logo">
        <img src={Logo} alt="Logo" />
      </Link>

      <ul className="Mechnav__list">
        <li
          className={`${active === "QR" ? "active" : ""} Mechnav__list__item`}
        >
          <Link to="/mech" className="Mechnav__list__item__link">
            <img
              src={active === "QR" ? scanActiveIcon : scanIcon}
              alt="Scan Icon"
            />
            Scan QR code
          </Link>
        </li>
        <li
          className={`${
            active === "Cards" ? "active" : ""
          } Mechnav__list__item`}
        >
          <Link to="/mech/cards" className="Mechnav__list__item__link">
            <img
              src={active === "Cards" ? documentActiveIcon : documentIcon}
              alt="Cards Icon"
            />
            Maintenance cards
          </Link>
        </li>
        <li className="Mechnav__list__item">
          <button className="Mechnav__list__item__link">
            <img src={logoutIcon} alt="Logout icon" /> Log out
          </button>
        </li>
      </ul>

      {/* User */}
      <div className="Topnav__user">
        <p>Ahmed Mostafa</p>
        <img src="https://via.placeholder.com/32" alt="User avatar" />
      </div>
    </nav>
  );
};

export default MechTopNav;
