import { actionTypes } from "../actionTypes";
import { IClients } from "../storeTypes";

interface Iaction {
  type: string;
  isFailed: boolean;
  payload: any;
  errors: any;
}

const clients = (state: IClients, action: Iaction) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CLIENTS:
      return {
        ...state,
        clients: action.isFailed ? state.clients : action.payload,
        errors: !action.isFailed ? [] : action.errors,
      };
    case actionTypes.ADD_CLIENT:
    case actionTypes.EDIT_CLIENT:
      return {
        ...state,
        success: action.isFailed ? {} : { addClient: true },
        errors: !action.isFailed ? [] : action.errors,
      };
    case actionTypes.GET_CLIENT:
      return {
        ...state,
        client: action.isFailed ? state.client : action.payload,
        errors: !action.isFailed ? [] : action.errors,
      };
    default:
      return {
        ...state,
        success: {},
        errors: [],
      };
  }
};

export default clients;
