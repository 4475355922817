import React from "react";
import { Link } from "react-router-dom";

interface Props {
  header: string[];
  rows: { [key: string]: any }[];
  link?: string[];
}

const Table: React.FC<Props> = ({ header, rows, link }) => {
  return (
    <div className="Table">
      <table className="Table__container">
        <thead>
          <tr className="Table__container__header">
            {header.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, i) => (
            <tr key={i} className="Table__container__row">
              {Object.entries(row).map(([key, data], j) => (
                <td
                  key={j}
                  className={
                    key === "status"
                      ? `status status-${data}`
                      : key === "available"
                      ? `status status-${data === "Yes" ? "Open" : "Closed"}`
                      : ""
                  }
                >
                  {link && !j ? (
                    <Link to={link[i]}>
                      {key === "id" ? "#" : ""}
                      {data}
                    </Link>
                  ) : (
                    <span>
                      {key === "id" ? "#" : ""}
                      {data}
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
